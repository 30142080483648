import {typeOfAttributeOptions} from "../constants/typeOfAttributeOptions";
import lodash from "lodash";

export function nav(data) {
    const parents = data.filter((item) => item.parentId === null);
    parents.sort((a, b) => a.orderNumber - b.orderNumber);
    return {
        items: parents.map((item) => {
            return navNode(item, data);
        })
    };
}

function navNode(node, data) {
    var result = {};
    if (node.isTitle) {
        result = {
            title: true,
            name: node.name,
            class: node.className,
        };
        for (let i = 0; i < typeOfAttributeOptions.length; i++) {
            lodash.set(result, typeOfAttributeOptions[i].value, navAttributes(node.attributes, typeOfAttributeOptions[i].value));
        }
        return result;
    } else {
        result = {
            name: node.name,
            url: node.url,
            icon: node.icon,
            variant: node.variant,
        };
        for (let i = 0; i < typeOfAttributeOptions.length; i++) {
            lodash.set(result, typeOfAttributeOptions[i].value, navAttributes(node.attributes, typeOfAttributeOptions[i].value));
        }

        let children = data.filter((item) => item.parentId === node.id);
        children.sort((a, b) => a.orderNumber - b.orderNumber);
        if (children.length > 0) {
            result.children = [];
        }
        for (let i = 0; i < children.length; i++) {
            result.children.push(navNode(children[i], data));
        }
        return result;
    }
}

function navAttributes(attributes, typeOfAttribute) {
    let _attributes = attributes.filter(item => item.typeOfAttribute === typeOfAttribute);
    let result = {};
    for (let i = 0; i < _attributes.length; i++) {
        lodash.set(result, _attributes[i].name, _attributes[i].value);
    }
    return result;
}
