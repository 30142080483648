import {API_BASE_URL, ACCESS_TOKEN} from '../constants';
import {request} from '../API/base';

export function updateMenu(menuEditModel) {
    return request({
        url: API_BASE_URL + "/menu/update-menu",
        method: 'POST',
        body: JSON.stringify(menuEditModel)
    },true);
}

export function getMenu(id) {
    return request({
        url: API_BASE_URL + "/menu/get/" + id,
        method: 'GET'
    },true);
}

export function getMenus(searchQuery) {
    return request({
        url: API_BASE_URL + `/menu/all`,
        method: 'POST',
        body: JSON.stringify(searchQuery)
    },true);
}

export function getMenuAdmins() {
    return request({
        url: API_BASE_URL + `/menu-admin`,
        method: 'GET',
    },true);
}

export function getHeaderMenuAdmins() {
    return request({
        url: API_BASE_URL + `/menu-admin-header`,
        method: 'GET',
    },true);
}

export function getMenuLandingPages() {
    return request({
        url: API_BASE_URL + "/menu-landing-page/",
        method: 'GET',
    },true);
}

export function deleteMenu(id) {
    return request({
        url: API_BASE_URL + `/menu/delete-menu/` + id,
        method: 'DELETE'
    },true);
}

export function deleteMenus(ids) {
    return request({
        url: API_BASE_URL + `/menu/bulk-delete/`,
        method: 'POST',
        body: JSON.stringify({ids:ids})
    },true);
}
