import * as axios from "axios";

export class GruDeviceAgent {

    reasonNotRunning = (error) => Promise.reject({
        statusText: "NOT_RUNNING",
        message: "Agent service is not running!",
        error: error
    });

    reasonNotSupported = () => Promise.reject({
        statusText: "NOT_SUPPORTED",
        message: "The operating system is not supported by PC(Web) Agent"
    });

    constructor(os: string, url: string) {
        this.os = os
        this.url = url
        if (os === "Windows") {
            this.tokenUrl = this.url + "/GetToken";
            this.getDeviceInfoUrl = this.url + "/GetDeviceInfo";
        }

        if (os === "Mac OS") {
            this.checkStatus = this.url + "/checkStatus";
            this.getDeviceInfoUrl = this.url + "/deviceInfo";
        }

    }

    static of(os: string, url: string) {
        return new GruDeviceAgent(os, url);
    }

    getDeviceInfo() {
        if (this.os === "Windows")
            return this._getWindowDeviceInfo();

        if (this.os === "Mac OS")
            return this._getMacDeviceInfo();

        return this.reasonNotSupported;
    }

    _getMacDeviceInfo() {
        return axios({
            method: 'GET',
            url: this.checkStatus,
            responseType: 'application/json'
        }).then(statusResponse => {
            if (statusResponse.status === 200) {
                return axios({
                    method: 'GET',
                    url: this.getDeviceInfoUrl,
                    responseType: 'application/json'
                })
            } else {
                return this.reasonNotRunning;
            }
        }).catch(error => {
            return this.reasonNotRunning(error);
        });
    }

    _getWindowDeviceInfo() {
        return axios({
            method: 'POST',
            url: this.tokenUrl,
            responseType: 'application/json'
        }).then(tokenResponse => {
            let token = null;
            if (tokenResponse.status === 200) {
                token = tokenResponse.data;
            }
            if (token == null || token == "") {
                return this.reasonNotRunning;
            } else {
                return axios({
                    method: 'POST',
                    url: this.getDeviceInfoUrl,
                    data: JSON.stringify({token: token}),
                    responseType: 'application/json'
                });
            }
        }).catch(error => {
            return this.reasonNotRunning(error);
        });
    }
}
