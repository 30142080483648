'use strict';
import UAParser from 'ua-parser-js';
import fp from 'fingerprintjs2';
import fp2 from 'fingerprintjs2';

export class GruDevice {

    constructor(options: any) {
        this.collectionType = options["collectionType"] ?? "WEB_WTS_AGENTLESS";
        this.applicationName = options["applicationName"];
        this.IGNORE_FINGERPRINT_ITEM_INDEX = options ? options["IGNORE_FINGERPRINT_ITEM_INDEX"] : [];
    }

    static of(options: any) {
        return new GruDevice(options);
    }

    UA = new UAParser();
    browser = this.UA.getBrowser();
    cpu = this.UA.getCPU();
    device = this.UA.getDevice();
    engine = this.UA.getEngine();
    os = this.UA.getOS();
    ua = this.UA.getUA();

    setDefaults = function setDefaults(p) {
        let d = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'none';
        return p ? p : d;
    };

    getNavigatorInstance = function getNavigatorInstance() {
        if (typeof window !== 'undefined') {
            if (window.navigator || navigator) {
                return window.navigator || navigator;
            }
        }

        return false;
    };
    isIOS13Check = function isIOS13Check(type) {
        var nav = this.getNavigatorInstance();
        return nav && (nav.platform.indexOf(type) !== -1 || nav.platform === 'MacIntel' && nav.maxTouchPoints > 1 && !window.MSStream);
    };

    DEVICE_TYPES = {
        MOBILE: 'mobile',
        TABLET: 'tablet',
        SMART_TV: 'smarttv',
        CONSOLE: 'console',
        WEARABLE: 'wearable',
        BROWSER: undefined
    };
    BROWSER_TYPES = {
        CHROME: 'Chrome',
        FIREFOX: "Firefox",
        OPERA: "Opera",
        YANDEX: "Yandex",
        SAFARI: "Safari",
        INTERNET_EXPLORER: "Internet Explorer",
        EDGE: "Edge",
        CHROMIUM: "Chromium",
        IE: 'IE',
        MOBILE_SAFARI: "Mobile Safari"
    };
    OS_TYPES = {
        IOS: 'iOS',
        ANDROID: "Android",
        WINDOWS_PHONE: "Windows Phone"
    };
    initialData = {
        isMobile: false,
        isTablet: false,
        isBrowser: false,
        isSmartTV: false,
        isConsole: false,
        isWearable: false
    };
    checkType = (type) => {
        switch (type) {
            case this.DEVICE_TYPES.MOBILE:
                return {
                    isMobile: true
                };

            case this.DEVICE_TYPES.TABLET:
                return {
                    isTablet: true
                };

            case this.DEVICE_TYPES.SMART_TV:
                return {
                    isSmartTV: true
                };

            case this.DEVICE_TYPES.CONSOLE:
                return {
                    isConsole: true
                };

            case this.DEVICE_TYPES.WEARABLE:
                return {
                    isWearable: true
                };

            case this.DEVICE_TYPES.BROWSER:
                return {
                    isBrowser: true
                };

            default:
                return this.initialData;
        }
    };

    broPayload = (isBrowser, browser, engine, os, ua) => {
        return {
            type: this.DEVICE_TYPES.BROWSER,
            isBrowser: isBrowser,
            browserMajorVersion: this.setDefaults(browser.major),
            browserFullVersion: this.setDefaults(browser.version),
            browserName: this.setDefaults(browser.name),
            engineName: this.setDefaults(engine.name),
            engineVersion: this.setDefaults(engine.version),
            osName: this.setDefaults(os.name),
            osVersion: this.setDefaults(os.version),
            userAgent: this.setDefaults(ua)
        };
    };

    mobilePayload = (type, browser, device, os, ua) => {
        return {
            type: type.isMobile ? this.DEVICE_TYPES.MOBILE : this.DEVICE_TYPES.TABLET,
            browserMajorVersion: this.setDefaults(browser.major),
            browserFullVersion: this.setDefaults(browser.version),
            browserName: this.setDefaults(browser.name),
            vendor: this.setDefaults(device.vendor),
            model: this.setDefaults(device.model),
            osName: this.setDefaults(os.name),
            osVersion: this.setDefaults(os.version),
            ua: this.setDefaults(ua)
        };
    };

    stvPayload = (isSmartTV, browser, engine, os, ua) => {
        return {
            type: this.DEVICE_TYPES.SMART_TV,
            browserMajorVersion: this.setDefaults(browser.major),
            browserFullVersion: this.setDefaults(browser.version),
            browserName: this.setDefaults(browser.name),
            isSmartTV: isSmartTV,
            engineName: this.setDefaults(engine.name),
            engineVersion: this.setDefaults(engine.version),
            osName: this.setDefaults(os.name),
            osVersion: this.setDefaults(os.version),
            userAgent: this.setDefaults(ua)
        };
    };

    consolePayload = (isConsole, browser, engine, os, ua) => {
        return {
            type: this.DEVICE_TYPES.CONSOLE,
            isConsole: isConsole,
            browserMajorVersion: this.setDefaults(browser.major),
            browserFullVersion: this.setDefaults(browser.version),
            browserName: this.setDefaults(browser.name),
            engineName: this.setDefaults(engine.name),
            engineVersion: this.setDefaults(engine.version),
            osName: this.setDefaults(os.name),
            osVersion: this.setDefaults(os.version),
            userAgent: this.setDefaults(ua)
        };
    };
    wearPayload = (isWearable, browser, engine, os, ua) => {
        return {
            type: this.DEVICE_TYPES.WEARABLE,
            isWearable: isWearable,
            browserMajorVersion: this.setDefaults(browser.major),
            browserFullVersion: this.setDefaults(browser.version),
            browserName: this.setDefaults(browser.name),
            engineName: this.setDefaults(engine.name),
            engineVersion: this.setDefaults(engine.version),
            osName: this.setDefaults(os.name),
            osVersion: this.setDefaults(os.version),
            userAgent: this.setDefaults(ua)
        };
    };

    type = this.checkType(this.device.type);

    getFingerprint = () =>
        new Promise(resolve => {
            fp.get(components => {
                resolve(components);
            });
        });

    detect() {
        let isBrowser = this.type.isBrowser,
            isMobile = this.type.isMobile,
            isTablet = this.type.isTablet,
            isSmartTV = this.type.isSmartTV,
            isConsole = this.type.isConsole,
            isWearable = this.type.isWearable;

        let payload = {};

        if (isBrowser) {
            payload = this.broPayload(isBrowser, this.browser, this.engine, this.os, this.ua);
        }

        if (isSmartTV) {
            payload = this.stvPayload(isSmartTV, this.browser, this.engine, this.os, this.ua);
        }

        if (isConsole) {
            payload = this.consolePayload(isConsole, this.browser, this.engine, this.os, this.ua);
        }

        if (isMobile) {
            payload = this.mobilePayload(this.type, this.browser, this.device, this.os, this.ua);
        }

        if (isTablet) {
            payload = this.mobilePayload(this.type, this.browser, this.device, this.os, this.ua);
        }

        if (isWearable) {
            payload = this.wearPayload(isWearable, this.browser, this.engine, this.os, this.ua);
        }

        return fetch("https://extreme-ip-lookup.com/json", {cache: 'no-store'})           // Get the IP data
            .then(res => res.json())
            .then(ip => Promise.all([ip, this.getFingerprint()]))    // Get the fingerprint
            .then(([ip, fp]) => {

                let values = fp.map(function (item) {
                    return item.value
                });
                let strToHash = values.filter((item, idx) => !this.IGNORE_FINGERPRINT_ITEM_INDEX.includes(idx)).join('');
                let deviceUId = fp2.x64hash128(strToHash, 31).toUpperCase();

                let fpMap = {};
                fp.map(item => fpMap[item.key] = JSON.stringify(item.value));

                let result = {
                    device_uid: deviceUId,
                    collection_type: this.collectionType,
                    application_name: this.applicationName,
                    sensors: {
                        "WEB_IP": ip.status === "success" ? ip.query : null,
                        "WEB_CITY": ip.status === "success" ? ip.city : null,
                        "WEB_COUNTRY_CODE": ip.status === "success" ? ip.countryCode : null,
                        "CITY_LAT": ip.status === "success" ? ip.lat : null,
                        "CITY_LNG": ip.status === "success" ? ip.lon : null,
                        "BROWSER": payload["browserName"] + "(" + payload["browserFullVersion"] + ")",
                        "SYS_LANGUAGE_CODE": fpMap.language,
                        "OS_CODE": payload["osName"] + " " + payload["osVersion"],
                        "CONNECT_TERMINAL_TYPE": payload["type"] ?? "computer"
                    }
                };

                for (let i = 0; i < fp.length; i++) {
                    result.sensors[fp[i].key] = JSON.stringify(fp[i].value);
                }
                return result;
            });
    }

    deviceUID_async() {
        return this.getFingerprint().then((fp) => {
            let values = fp.map(function (item) {
                return item.value
            });
            let strToHash = values.filter((item, idx) => !this.IGNORE_FINGERPRINT_ITEM_INDEX.includes(idx)).join('');
            return fp2.x64hash128(strToHash, 31).toUpperCase();
        });
    }

    isMobileType = () => {
        return this.device.type === this.DEVICE_TYPES.MOBILE;
    };

    isTabletType = () => {
        return this.device.type === this.DEVICE_TYPES.TABLET;
    };

    isMobileAndTabletType = () => {
        switch (this.device.type) {
            case this.DEVICE_TYPES.MOBILE:
            case this.DEVICE_TYPES.TABLET:
                return true;

            default:
                return false;
        }
    };

    isSmartTVType = () => {
        return this.device.type === this.DEVICE_TYPES.SMART_TV;
    };

    isBrowserType = () => {
        return this.device.type === this.DEVICE_TYPES.BROWSER;
    };

    isWearableType = () => {
        return this.device.type === this.DEVICE_TYPES.WEARABLE;
    };

    isConsoleType = () => {
        return this.device.type === this.DEVICE_TYPES.CONSOLE;
    };

    isAndroidType = () => {
        return this.os.name === this.OS_TYPES.ANDROID;
    };

    isWinPhoneType = () => {
        return this.os.name === this.OS_TYPES.WINDOWS_PHONE;
    };

    isIOSType = () => {
        return this.os.name === this.OS_TYPES.IOS;
    };

    isChromeType = () => {
        return this.browser.name === this.BROWSER_TYPES.CHROME;
    };

    isFirefoxType = () => {
        return this.browser.name === this.BROWSER_TYPES.FIREFOX;
    };

    isChromiumType = () => {
        return this.browser.name === this.BROWSER_TYPES.CHROMIUM;
    };

    isEdgeType = () => {
        return this.browser.name === this.BROWSER_TYPES.EDGE;
    };

    isYandexType = () => {
        return this.browser.name === this.BROWSER_TYPES.YANDEX;
    };

    isSafariType = () => {
        return this.browser.name === this.BROWSER_TYPES.SAFARI || this.browser.name === this.BROWSER_TYPES.MOBILE_SAFARI;
    };

    isMobileSafariType = () => {
        return this.browser.name === this.BROWSER_TYPES.MOBILE_SAFARI;
    };

    isOperaType = () => {
        return this.browser.name === this.BROWSER_TYPES.OPERA;
    };

    isIEType = () => {
        return this.browser.name === this.BROWSER_TYPES.INTERNET_EXPLORER || this.browser.name === this.BROWSER_TYPES.IE;
    };

    isElectronType = () => {
        let nav = this.getNavigatorInstance();
        let ua = nav && nav.userAgent.toLowerCase();
        return typeof ua === 'string' ? /electron/.test(ua) : false;
    };

    getIOS13 = () => {
        let nav = this.getNavigatorInstance();
        return nav && (/iPad|iPhone|iPod/.test(nav.platform) || nav.platform === 'MacIntel' && nav.maxTouchPoints > 1) && !window.MSStream;
    };

    getIPad13 = () => {
        return this.isIOS13Check('iPad');
    };

    getIphone13 = () => {
        return this.isIOS13Check('iPhone');
    };

    getIPod13 = () => {
        return this.isIOS13Check('iPod');
    };

    getBrowserFullVersion = () => {
        return this.setDefaults(this.browser.version);
    };

    getBrowserVersion = () => {
        return this.setDefaults(this.browser.major);
    };

    getOsVersion = () => {
        return this.setDefaults(this.os.version);
    };

    getOsName = () => {
        return this.setDefaults(this.os.name);
    };

    getBrowserName = () => {
        return this.setDefaults(this.browser.name);
    };

    getMobileVendor = () => {
        return this.setDefaults(this.device.vendor);
    };

    getMobileModel = () => {
        return this.setDefaults(this.device.model);
    };

    getEngineName = () => {
        return this.setDefaults(this.engine.name);
    };

    getEngineVersion = () => {
        return this.setDefaults(this.engine.version);
    };

    getUseragent = () => {
        return this.setDefaults(this.ua);
    };

    getDeviceType = () => {
        return this.setDefaults(this.device.type, 'browser');
    };

    isSmartTV = this.isSmartTVType();
    isConsole = this.isConsoleType();
    isWearable = this.isWearableType();
    isMobileSafari = this.isMobileSafariType() || this.getIPad13();
    isChromium = this.isChromiumType();
    isMobile = this.isMobileAndTabletType() || this.getIPad13();
    isMobileOnly = this.isMobileType();
    isTablet = this.isTabletType() || this.getIPad13();
    isBrowser = this.isBrowserType();
    isAndroid = this.isAndroidType();
    isWinPhone = this.isWinPhoneType();
    isIOS = this.isIOSType() || this.getIPad13();
    isChrome = this.isChromeType();
    isFirefox = this.isFirefoxType();
    isSafari = this.isSafariType();
    isOpera = this.isOperaType();
    isIE = this.isIEType();
    osVersion = this.getOsVersion();
    osName = this.getOsName();
    fullBrowserVersion = this.getBrowserFullVersion();
    browserVersion = this.getBrowserVersion();
    browserName = this.getBrowserName();
    mobileVendor = this.getMobileVendor();
    mobileModel = this.getMobileModel();
    engineName = this.getEngineName();
    engineVersion = this.getEngineVersion();
    getUA = this.getUseragent();
    isEdge = this.isEdgeType();
    isYandex = this.isYandexType();
    deviceType = this.getDeviceType();
    isIOS13 = this.getIOS13();
    isIPad13 = this.getIPad13();
    isIPhone13 = this.getIphone13();
    isIPod13 = this.getIPod13();
    isElectron = this.isElectronType();
}
