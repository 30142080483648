import { API_BASE_URL, ACCESS_TOKEN } from '../constants';
import {request} from '../API/base';

export function updateUser(userEditModel) {
    return request({
        url: API_BASE_URL + "/user/update-user",
        method: 'POST',
        body: JSON.stringify(userEditModel)
    },true);
}

export function checkUsernameAvailability(username) {
    return request({
        url: API_BASE_URL + "/user/check-username-availability?username=" + username,
        method: 'GET'
    },true);
}

export function checkEmailAvailability(email) {
    return request({
        url: API_BASE_URL + "/user/check-email-availability?email=" + email,
        method: 'GET'
    },true);
}

export function getUserProfile(id) {
    return request({
        url: API_BASE_URL + "/user/profile-by-id/" + id,
        method: 'GET'
    },true);
}

export function getCurrentUserProfile() {
    return request({
        url: API_BASE_URL + "/user/profile",
        method: 'GET'
    },true);
}


export function getCurrentUser() {
    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    },true);
}

export function getUsers(searchQuery) {
    return request({
        url: API_BASE_URL + `/user/all`,
        method: 'POST',
        body: JSON.stringify(searchQuery)
    },true);
}

export function deleteUser(id) {
    return request({
        url: API_BASE_URL + `/user/delete-user/` + id,
        method: 'DELETE'
    },true);
}

export function deleteUsers(ids) {
    return request({
        url: API_BASE_URL + `/user/bulk-delete/`,
        method: 'POST',
        body: JSON.stringify({ids:ids})
    },true);
}

export function getUser(id) {
    return request({
        url: API_BASE_URL + "/user/get/" + id,
        method: 'GET'
    },true);
}

export function resetPassword(id) {
    return request({
        url: API_BASE_URL + `/user/reset-password/${id}`,
        method: 'POST',
    },true);
}

export function lockAccount(id) {
    return request({
        url: API_BASE_URL + `/user/lock-account/${id}`,
        method: 'POST',
    },true);
}

export function lockCurrentAccount() {
    return request({
        url: API_BASE_URL + `/user/lock-me`,
        method: 'POST',
    },true);
}

export function unlockAccount(id) {
    return request({
        url: API_BASE_URL + `/user/unlock-account/${id}`,
        method: 'POST',
    },true);
}
