export const API_BASE_URL = (process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_BASE_URL_DEV : (process.env.NODE_ENV == "production" ? process.env.REACT_APP_API_BASE_URL_PROD : null)) || 'http://localhost:8080/api';
export const ACCESS_TOKEN = 'accessToken';
export const CURRENT_USER = 'currentUser';

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 40;

export const HOST_MAX_LENGTH = 250;

export const USERNAME_MIN_LENGTH = 3;
export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

export const DESCRIPTION_MIN_LENGTH = 4;
export const DESCRIPTION_MAX_LENGTH = 250;

export const MAX_SAFE_INTEGER = 2147483647;

export const VARIANT_OPTIONS_DEFAULT = [{value: "primary", label: "primary"},
    {value: "secondary", label: "secondary"},
    {value: "success", label: "success"},
    {value: "danger", label: "danger"},
    {value: "warning", label: "warning"},
    {value: "info", label: "info"},
    {value: "light", label: "light"},
    {value: "dark", label: "dark"}];

export const IGNORE_FINGERPRINT_ITEM_INDEX = [0, 6, 7, 9];

export const PC_AGENT_URL = {
    "Windows": "http://localhost:8888",
    "Mac OS": "http://localhost:8868"
};
