import { API_BASE_URL, ACCESS_TOKEN } from '../constants';
import {request} from '../API/base';

export function getSettingsByType(type) {
    return request({
        url: API_BASE_URL + "/setting/type/" + type,
        method: 'GET'
    },true);
}

export function prepareSettingFilter() {
    return request({
        url: API_BASE_URL + '/setting/prepare-setting-filter',
        method: 'GET'
    },true);
}

export function prepareSettingEdit() {
    return request({
        url: API_BASE_URL + '/setting/prepare-setting-edit',
        method: 'GET'
    },true);
}

///setting/toggle-setting
export function toggleSetting(settingEditModel) {
    return request({
        url: API_BASE_URL + "/setting/toggle-setting",
        method: 'POST',
        body: JSON.stringify(settingEditModel)
    },true);
}
