import React, {Component} from 'react';
import {BrowserRouter, HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import {withRouter} from 'react-router'
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import NotificationExt from "./components/NotificationExt";
import {getMenuLandingPages} from "./util/API/menu";
import {nav} from "./util/menu/nav";

import * as reactDeviceDetect from 'react-device-detect'
import {CrudApi} from "./util/API/CrudApi";
import {ACCESS_TOKEN, CURRENT_USER, IGNORE_FINGERPRINT_ITEM_INDEX, PC_AGENT_URL} from "./util/constants";
import {GruDevice} from "./util/gru-device-js";
/*import GruDeviceAgent from './util/gru-device-js/agent'*/
import {typeofStatusMonitoring} from "./util/constants/typeofStatusMonitoringOptions";
import {getSettingsByType} from "./util/API/setting";
import {getCurrentUser} from "./util/API/user";
import {GruDeviceAgent} from "./util/gru-device-js/gru-device-agent";
/*import GruDeviceAgentMac from "./util/gru-device-js/agent-mac";*/

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const WebAgent = React.lazy(() => import('./views/Pages/WebAgent/WebAgent'));

class App extends Component {
    loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            isAuthenticated: false,
            authenticatedMessage: "",
            loading: false
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);

    }

    handleLogin() {
        NotificationExt.success('You have successfully logged in!');
        this.setState({
            isAuthenticated: true
        });
        this.initApp().then(isAuthenticated => {
            this.props.history.push("/admin");
        });
    }

    initApp = () => {
        return new Promise((resolve, reject) => {
            this.setState({loading: true});
            let _isAuthenticated = false;
            let _currentUser = null;
            getCurrentUser()
                .then(response => {
                    localStorage.setItem(CURRENT_USER, JSON.stringify(response));
                    this.setState({
                        currentUser: response,
                        isAuthenticated: true
                    });

                    _isAuthenticated = true;
                    _currentUser = response;

                }).catch(error => {
                if (error.error === "Unauthorized") {
                    this.setState({
                        isAuthenticated: false,
                        authenticatedMessage: error.message,
                        isLoading: false
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    NotificationExt.error(error.message);
                }
            }).finally(() => {
                let _gruDevice = GruDevice.of({
                    collectionType: "WEB_WTS_AGENTLESS",
                    applicationName: "GRU-DEVICE-ADMIN",
                    IGNORE_FINGERPRINT_ITEM_INDEX: IGNORE_FINGERPRINT_ITEM_INDEX
                });
                _gruDevice.detect().then(deviceEditModel => {
                    CrudApi.of("device").update(deviceEditModel).then(response => {
                            this.setState({
                                loading: false
                            });
                        }
                    ).catch(error => {
                            NotificationExt.error(error.message);
                            this.setState({
                                loading: false
                            });
                        }
                    )
                });

                let ALLOW_PC_AGENT_DETECTION = null;

                if (localStorage.getItem(CURRENT_USER)) {
                    if (_currentUser && _currentUser.settings)
                        if (_currentUser.settings.filter(item => item.name === "ALLOW_PC_AGENT_DETECTION").length > 0) {
                            if (_currentUser.settings.filter(item => item.name === "ALLOW_PC_AGENT_DETECTION" && item.value === "Y").length > 0) {
                                ALLOW_PC_AGENT_DETECTION = true;
                            } else {
                                ALLOW_PC_AGENT_DETECTION = false;
                            }
                        }
                }
                if (ALLOW_PC_AGENT_DETECTION === null) {
                    getSettingsByType("account-settings").then(res => {
                        if (res.filter(item => item.name === "ALLOW_PC_AGENT_DETECTION").length > 0) {
                            if (res.filter(item => item.name === "ALLOW_PC_AGENT_DETECTION" && item.value === "Y").length > 0) {
                                ALLOW_PC_AGENT_DETECTION = true;
                            } else {
                                ALLOW_PC_AGENT_DETECTION = false;
                            }
                        }
                        if (ALLOW_PC_AGENT_DETECTION)
                            this.WebAgentDetection(_gruDevice, _isAuthenticated);
                    }).catch(error => {
                        NotificationExt.error(error);
                    })
                } else {
                    if (ALLOW_PC_AGENT_DETECTION)
                        this.WebAgentDetection(_gruDevice, _isAuthenticated);
                }

                resolve(_isAuthenticated);
            });
        });
    }

    componentDidMount() {
        this.initApp().then(isAuthenticated => {
            //init app success
        });
    }

    //Web agent
    WebAgentDetection(_gruDevice, _isAuthenticated) {
        GruDeviceAgent.of(_gruDevice.osName, PC_AGENT_URL[_gruDevice.osName]).getDeviceInfo().then(response => {
            if (response.status === 200) {
                let deviceInfo = response.data;
                if (deviceInfo) {
                    let deviceEditModel = {
                        application_name: "GRU-DEVICE-ADMIN",
                        collection_type: deviceInfo.collection_type,
                        device_uid: deviceInfo.device_uid,
                        sensors: {}
                    };
                    Object.entries(deviceInfo.sensors).filter(item => item[1] != null && item[1] != "").map(item => {
                        deviceEditModel.sensors[item[0]] = item[1];
                    });

                    //Get user information, ex: LOGIN_ID, AGE, BIRTHDAY

                    deviceEditModel.sensors.LOGIN_YN = "N";
                    if (_isAuthenticated) {
                        let currentUser = localStorage.getItem(CURRENT_USER);
                        deviceEditModel.sensors.LOGIN_YN = "Y";
                        deviceEditModel.sensors.LOGIN_ID = JSON.parse(currentUser).username;
                    }

                    //Send and store data to database

                    _gruDevice.deviceUID_async().then(device_uid => {
                        deviceEditModel.device_alt_uid = device_uid;
                    }).finally(() => {
                        CrudApi.of("device").update(deviceEditModel).then(response => {
                                //Success
                            }
                        ).catch(error => {
                                NotificationExt.error(error.message);
                            }
                        )
                    });
                }
            }
        }).catch(error => {
            if (error.message)
                NotificationExt.error(error.message);

            if (error.statusText === "NOT_RUNNING") {
                this.props.history.push("/web-agent");
            }
        });
    }

    handleLogout() {
        this.setState({
            currentUser: null,
            isAuthenticated: false,
        });
    }

    render() {
        return (
            this.state.loading ? this.loading() :
                <React.Suspense fallback={this.loading()}>
                    <Switch>
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                        <Route exact path="/web-agent" name="Web agent service installer"
                               render={props => <WebAgent {...props}/>}/>
                        <Route exact path="/login" name="Login Page"
                               render={props => <Login onLogin={this.handleLogin} {...props}/>}/>
                        <Route path="/" name="Admin panel"
                               render={props => <DefaultLayout {...props} currentUser={this.state.currentUser}
                                                               isAuthenticated={this.state.isAuthenticated}
                                                               authenticatedMessage={this.state.authenticatedMessage}
                                                               onLogout={() => this.handleLogout()}/>}/>
                    </Switch>
                </React.Suspense>
        );
    }
}

export default withRouter(App);
