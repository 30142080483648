import {request} from "./base";
import {API_BASE_URL} from "../constants";

export class CrudApi {

    constructor(functionName: string, hasACCESS_TOKEN: boolean) {
        this.functionName = functionName
        this.hasACCESS_TOKEN = hasACCESS_TOKEN
    }

    static of(functionName: string, hasACCESS_TOKEN: boolean) {
        return new CrudApi(functionName,hasACCESS_TOKEN);
    }

    update(functionEditModel, hasACCESS_TOKEN) {
        return request({
            url: API_BASE_URL + `/${this.functionName}/update-${this.functionName}`,
            method: 'POST',
            body: JSON.stringify(functionEditModel)
        }, ((hasACCESS_TOKEN === undefined) || (hasACCESS_TOKEN === null)) ? this.hasACCESS_TOKEN : hasACCESS_TOKEN);
    }

    get(id, hasACCESS_TOKEN) {
        return request({
            url: API_BASE_URL + `/${this.functionName}/get/` + id,
            method: 'GET'
        }, ((hasACCESS_TOKEN === undefined) || (hasACCESS_TOKEN === null)) ? this.hasACCESS_TOKEN : hasACCESS_TOKEN);
    }

    gets(searchQuery, hasACCESS_TOKEN) {

        return request({
            url: API_BASE_URL + `/${this.functionName}/all`,
            method: 'POST',
            body: JSON.stringify(searchQuery)
        }, ((hasACCESS_TOKEN === undefined) || (hasACCESS_TOKEN === null)) ? this.hasACCESS_TOKEN : hasACCESS_TOKEN);
    }

    delete(id, hasACCESS_TOKEN) {
        return request({
            url: API_BASE_URL + `/${this.functionName}/delete-${this.functionName}/` + id,
            method: 'DELETE'
        }, ((hasACCESS_TOKEN === undefined) || (hasACCESS_TOKEN === null)) ? this.hasACCESS_TOKEN : hasACCESS_TOKEN);
    }

    deletes(ids, hasACCESS_TOKEN) {
        return request({
            url: API_BASE_URL + `/${this.functionName}/bulk-delete/`,
            method: 'POST',
            body: JSON.stringify({ids: ids})
        }, ((hasACCESS_TOKEN === undefined) || (hasACCESS_TOKEN === null)) ? this.hasACCESS_TOKEN : hasACCESS_TOKEN);
    }
}
