import {toast, ToastOptions} from "react-toastify";

class NotificationExt{

    static options :ToastOptions= {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    };

    static noty(type,content)
    {
        if(type === 'SUCCESS') return this.success(content);
        if(type === 'INFO') return this.info(content);
        if(type === 'WARNING') return this.warn(content);
        if(type === 'ERROR') return this.error(content);
    }

    /**
     * Shorthand to display toast of type 'success'.
     */
    static success(content){
        toast.success(content, this.options);
    }

    /**
     * Shorthand to display toast of type 'info'.
     */
    static info(content){
        toast.info(content, this.options);
    }

    /**
     * Shorthand to display toast of type 'warning'.
     */
    static warn(content){
        toast.warn(content, this.options);
    }

    /**
     * Shorthand to display toast of type 'error'.
     */
    static error(content){
        toast.error(content, this.options);
    }
}

export default NotificationExt;
